import React, {useEffect, useContext} from 'react';
import { FabContext } from "../../context/FabContext";
import './style.scss';

const NotFound = () => {
        
    const{setLocal} = useContext(FabContext);
    useEffect(()=>{ setLocal('not'); },[]);

    return(
        <div className="notFound">

            <div className="notFound__center">
                <h1 className="notFound__center__headline">Sorry.</h1>
                <h2 className="notFound__center__text">Looks like that's not quite right.</h2>
            </div>

        </div>
    )
}
export default NotFound;
